/* this file is transformed by vux-loader */
/* eslint-disable */
import _createForOfIteratorHelper from "F:/zhaoyanning/\u9ED1\u9F99\u6C5F\u533B\u79D1\u5927\u5B66\u9644\u5C5E\u533B\u9662/mobile-v2/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import _toConsumableArray from "F:/zhaoyanning/\u9ED1\u9F99\u6C5F\u533B\u79D1\u5927\u5B66\u9644\u5C5E\u533B\u9662/mobile-v2/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Group from 'vux/src/components/grid/grid';
import XInput from 'vux/src/components/x-input';
import PopupPicker from 'vux/src/components/popup-picker';
import Datetime from 'vux/src/components/datetime';
import XTextarea from 'vux/src/components/x-textarea';
import XButton from 'vux/src/components/x-button';
import UploadImage from '@/components/UploadImage/UploadImage';
import OrgTree from '@/components/OrgTree/OrgTree';
export default {
  name: 'ChangeShift',
  components: {
    Group: Group,
    XInput: XInput,
    PopupPicker: PopupPicker,
    Datetime: Datetime,
    XTextarea: XTextarea,
    OrgTree: OrgTree,
    XButton: XButton,
    UploadImage: UploadImage
  },
  beforeRouteLeave: function beforeRouteLeave(to, from, next) {
    if (this.showFlag) {
      next(false);
      this.showFlag = false;
    } else {
      this.$utils.Store.removeItem('oldClassesName');
      this.$utils.Store.removeItem('oldClasses');
      this.$utils.Store.removeItem('changeDate');
      this.$utils.Store.removeItem('reason');
      next();
    }
  },
  data: function data() {
    return {
      actions: this.$api.Base.dev + '/management/insertPhoto',
      //上传图片接口
      bh: '00202',
      //假类编号
      classesList: [],
      newClasses: this.$utils.Store.getItem('newClasses') ? JSON.parse(this.$utils.Store.getItem('newClasses')) : [],
      oldClassesName: this.$utils.Store.getItem('oldClassesName') ? this.$utils.Store.getItem('oldClassesName') : '',
      //原班次名称
      oldClasses: this.$utils.Store.getItem('oldClasses') ? this.$utils.Store.getItem('oldClasses') : '',
      //原班次编号
      startDate: '',
      //今天日期
      changeDate: this.$utils.Store.getItem('changeDate') ? this.$utils.Store.getItem('changeDate') : '',
      //调班时间
      endTime: '',
      //开始时间
      reason: this.$utils.Store.getItem('reason') ? this.$utils.Store.getItem('reason') : '',
      //外出目的
      approver: this.$utils.Store.getItem('oldVal') ? JSON.parse(this.$utils.Store.getItem('oldVal')) : [],
      //审批人
      copyer: [],
      //固定抄送人数组
      customer: [],
      //自定义抄送人数组
      copyerChecked: [],
      //抄送人序号
      inputParam: [{
        name: 'att_id',
        value: 0
      }, {
        name: 'base64String',
        value: ''
      }, {
        name: 'typeId',
        value: -1
      }],
      imgList: [],
      //图片列表数组
      limitNum: 5,
      //最多上传图片张数
      depSerial: this.$utils.Store.getItem('orgId'),
      //企业ID
      depName: this.$utils.Store.getItem('orgName'),
      //企业名称
      imgPath: [],
      //图片地址
      imgIndex: 0,
      //图片数组下标
      choose: this.$utils.Store.getItem('choose') ? this.$utils.Store.getItem('choose') : 0,
      //添加人员类型   1：审批人  2：抄送人  3：同行人
      showFlag: false,
      //是否显示组织架构弹窗
      selectType: 0,
      //组织架构选择类型
      infoChecked: [],
      //
      approverChecked: [],
      //审批人选中
      customerChecked: [],
      //自定义抄送人选中
      icon: 'icon-jiahao' //icon加号

    };
  },
  computed: {
    isDisabled: function isDisabled() {
      if (this.changeDate != '' && this.oldClasses != '' && this.newClasses.length > 0 && this.reason != '' && this.approver.length > 0) {
        /**/
        return false;
      } else {
        return true;
      }
    },
    getInfoChecked: function getInfoChecked() {
      if (this.selectType == 1 && this.approverChecked.length > 0) {
        return this.approverChecked;
      } else if (this.selectType == 9999 && this.customerChecked.length > 0) {
        return this.customerChecked;
      }

      return [];
    }
  },
  methods: {
    /** function 获取服务器时间---今日日期
     *   wzx
     *   2019-01-11
     **/
    getSysDate: function getSysDate() {
      var _this = this;

      this.$utils.Tools.loadShow();
      this.$api.Sys.getSystemDate(this.$params.mode).then(function (response) {
        _this.$utils.Tools.loadHide();

        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== _this.$code.success) {
          _this.$utils.Tools.toastShow(msg);
        } else {
          _this.startDate = data.sysDate.substring(0, 10);
        }
      }).catch(function () {
        _this.$utils.Tools.httpError();
      });
    },

    /** function 获取外出事由
     *   wzx
     *   2019-01-05
     **/
    getReason: function getReason(val) {
      this.reason = val;
      this.$utils.Store.setItem('reason', val);
    },

    /** function 获取调班日期
     *   wzx
     *   2019-01-05
     **/
    changeDay: function changeDay(val) {
      this.changeDate = val;
      this.$utils.Store.setItem('changeDate', val);
      this.getBanci();
    },

    /** function 获取新班次
     *   wzx
     *   2019-01-05
     **/
    changeShift: function changeShift(val) {
      this.newClasses = val;
      this.$utils.Store.setItem('newClasses', JSON.stringify(val));
    },

    /** function 获取原班次和班次列表
     *   wzx
     *   2019-01-08
     **/
    getBanci: function getBanci() {
      var _this2 = this;

      this.$api.ChangeShift.getBanci({
        tbrq: this.changeDate,
        userSerial: this.$utils.Store.getItem('userSerial')
      }).then(function (response) {
        _this2.$utils.Tools.loadHide();

        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== _this2.$code.success) {
          _this2.$utils.Tools.toastShow(msg);
        } else {
          _this2.oldClassesName = data.banci.mc;
          _this2.oldClasses = data.banci.bh;

          _this2.$utils.Store.setItem('oldClassesName', data.banci.mc);

          _this2.$utils.Store.setItem('oldClasses', data.banci.bh);

          var allBC = data.BanciAll;

          for (var i = 0; i < allBC.length; i++) {
            _this2.classesList.push({
              name: allBC[i].mc,
              value: allBC[i].bh
            });
          }
        }
      }).catch(function () {
        _this2.$utils.Tools.httpError();
      });
    },
    onChange: function onChange() {},
    onShow: function onShow() {},
    onHide: function onHide() {},

    /** function 获取审批人数组
     *   wzx
     *   2018-12-26
     * */
    selectUsers: function selectUsers(val) {
      if (this.selectType == 1) {
        if (this.approver.length > 0) {
          var result = this.approver.some(function (item) {
            if (item.userSerial == val[0].userSerial) {
              return true;
            }
          });

          if (result) {
            this.$utils.Tools.toastShow('审批人不可重复');
          } else {
            var _this$approver;

            (_this$approver = this.approver).push.apply(_this$approver, _toConsumableArray(val));
          }
        } else {
          var _this$approver2;

          (_this$approver2 = this.approver).push.apply(_this$approver2, _toConsumableArray(val));
        }
      } else {
        if (this.customer.length > 0) {
          var _this$customer;

          this.customer = [];

          (_this$customer = this.customer).push.apply(_this$customer, _toConsumableArray(val));

          var hash = {};
          /*es6数组去重*/

          var newArr = this.customer.reduceRight(function (item, next) {
            hash[next.userSerial] ? '' : hash[next.userSerial] = true && item.push(next);
            return item;
          }, []);
          this.customer = newArr;

          var _iterator = _createForOfIteratorHelper(this.customer),
              _step;

          try {
            for (_iterator.s(); !(_step = _iterator.n()).done;) {
              var b = _step.value;
              this.customerChecked.push(b.userSerial);
            }
          } catch (err) {
            _iterator.e(err);
          } finally {
            _iterator.f();
          }
        } else {
          var _this$customer2;

          (_this$customer2 = this.customer).push.apply(_this$customer2, _toConsumableArray(val));

          var _iterator2 = _createForOfIteratorHelper(this.customer),
              _step2;

          try {
            for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
              var _b = _step2.value;
              this.customerChecked.push(_b.userSerial);
            }
          } catch (err) {
            _iterator2.e(err);
          } finally {
            _iterator2.f();
          }
        }
      }
    },
    selectApprovalUser: function selectApprovalUser() {
      var _this3 = this;

      this.$utils.Tools.loadShow();
      this.$api.Approver.selectApprovalUser({
        userSerial: this.$utils.Store.getItem('userSerial')
      }).then(function (response) {
        _this3.$utils.Tools.loadHide();

        var code = response.code,
            data = response.data;

        if (+code !== _this3.$code.success) {// this.$utils.Tools.toastShow(msg);
        } else {
          var _this3$approver;

          _this3.approver = [];

          (_this3$approver = _this3.approver).push.apply(_this3$approver, _toConsumableArray(data));

          _this3.$utils.Store.setItem('oldVal', JSON.stringify(data[0]));
        }
      }).catch(function () {
        _this3.$utils.Tools.httpError();
      });
    },

    /** function 删除审批人
     *   wzx
     *   2018-12-28
     * */
    deleteApprover: function deleteApprover(index) {
      this.approver.splice(index, 1);
    },

    /** function 获取固定抄送人数组
     *   wzx
     *   2018-12-27
     **/
    selectCopyUser: function selectCopyUser() {
      var _this4 = this;

      this.$api.Copyer.selectCopyUser({
        lx: 4,
        //0:请假  1:出差  2:外出  3:加班  4:调班  5:补出勤 6:合同
        userSerial: this.$utils.Store.getItem('userSerial')
      }).then(function (response) {
        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== _this4.$code.success) {
          _this4.$utils.Tools.toastShow(msg);
        } else {
          var _this4$copyer;

          (_this4$copyer = _this4.copyer).push.apply(_this4$copyer, _toConsumableArray(data));
        }
      }).catch(function () {
        _this4.$utils.Tools.httpError();
      });
    },

    /** function 删除自定义抄送人
     *   wzx
     *   2019-01-04
     * */
    deleteCustomer: function deleteCustomer(index) {
      this.customer.splice(index, 1);
    },

    /** function 添加审批人
     *   wzx
     *   2018-12-29
     * */
    chooseApprover: function chooseApprover() {
      //copyType----1:请假 2:出差 3:外出 4:加班 5:调班6:补出勤  choose---1:审批人 2：抄送人  3：同行人
      this.infoChecked = [];
      this.selectType = 1;
      this.showFlag = true;
    },

    /** function 添加抄送人
     *   wzx
     *   2018-12-29
     * */
    chooseCopyer: function chooseCopyer() {
      //copyType----1:请假 2:出差 3:外出 4:加班 5:调班6:补出勤  choose---1:审批人 2：抄送人  3：同行人
      this.infoChecked = [];
      this.selectType = 9999;
      this.showFlag = true;

      var _iterator3 = _createForOfIteratorHelper(this.customer),
          _step3;

      try {
        for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
          var b = _step3.value;
          this.infoChecked.push(b.userSerial);
        }
      } catch (err) {
        _iterator3.e(err);
      } finally {
        _iterator3.f();
      }
    },

    /** function 提交出差申请
     *   wzx
     *   2019-01-05
     * */
    submitClick: function submitClick() {
      var _this5 = this;

      this.approverChecked = [];
      this.imgPath = [];

      var _iterator4 = _createForOfIteratorHelper(this.approver),
          _step4;

      try {
        for (_iterator4.s(); !(_step4 = _iterator4.n()).done;) {
          var i = _step4.value;
          this.approverChecked.push(i.userSerial);
        }
      } catch (err) {
        _iterator4.e(err);
      } finally {
        _iterator4.f();
      }

      var myapprover = JSON.stringify(this.approverChecked).replace(/\[|]|"/g, '');
      this.approverString = myapprover;
      var mycopyer = JSON.stringify(this.customerChecked).replace(/\[|]|"/g, '');
      this.customerString = mycopyer;

      var _iterator5 = _createForOfIteratorHelper(this.imgList),
          _step5;

      try {
        for (_iterator5.s(); !(_step5 = _iterator5.n()).done;) {
          var imgs = _step5.value;
          this.imgPath.push(imgs.serverId);
        }
      } catch (err) {
        _iterator5.e(err);
      } finally {
        _iterator5.f();
      }

      var imgPath = JSON.stringify(this.imgPath).replace(/\[|]|"/g, '');
      this.$utils.Tools.loadShow();
      this.$api.ChangeShift.insertChangeShift({
        bh: this.bh,
        bz: this.reason,
        imgPath: imgPath,
        processId: '',
        qjlx: 1,
        tbrq: this.changeDate,
        xbc: this.newClasses[0],
        ybc: this.oldClasses,
        userSerial: this.$utils.Store.getItem('userSerial'),
        xh: '',
        approver: this.approverString,
        copyUser: this.customerString
      }).then(function (response) {
        _this5.$utils.Tools.loadHide();

        var code = response.code,
            msg = response.msg;

        if (+code !== _this5.$code.success) {
          _this5.$utils.Tools.toastShow(msg);
        } else {
          _this5.$utils.Tools.toastShow('提交成功');

          _this5.$router.go(-1); // this.$router.replace('/MyApply')

        }
      }).catch(function () {
        _this5.$utils.Tools.httpError();
      });
    },

    /** 函数function 设定当前页面
     *  wzx
     *  2019.01.09
     **/
    pushHistory: function pushHistory() {
      var state = {
        title: 'title',
        url: ''
      };
      window.history.pushState(state, state.title, state.url);
    },
    getSysApproveUser: function getSysApproveUser() {
      var _this6 = this;

      this.approver = [];
      this.$api.DepUser.selectListByEntity({
        userSerial: this.$utils.Store.getItem('userSerial'),
        lx: 1
      }).then(function (response) {
        _this6.$utils.Tools.loadHide();

        var code = response.code,
            data = response.data;

        if (+code !== _this6.$code.success) {// this.$utils.Tools.toastShow(msg);
        } else {
          var _this6$approver;

          (_this6$approver = _this6.approver).push.apply(_this6$approver, _toConsumableArray(data));
        }
      }).catch(function () {
        _this6.$utils.Tools.httpError();
      });
    }
  },
  created: function created() {
    this.$utils.Store.removeItem('oldVal');
    this.getSysDate();

    if (this.$params.approve === '1') {
      this.selectApprovalUser();
    } else {
      this.getSysApproveUser();
    }

    this.selectCopyUser();
  },
  mounted: function mounted() {// this.pushHistory();
    // window.onpopstate = () => {
    //     this.$router.push('/Application')  //输入要返回的上一级路由地址
    // }
  },
  destroyed: function destroyed() {
    this.$utils.Store.removeItem('oldVal');
  }
};